<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        ข้อมูลสินค้า
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
        <v-spacer/>
         <v-btn :elevation="1" color="success" class="pa-2 mr-2 " @click.native="$router.push('/my-product/update/excel')">
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          เพิ่มรายการใหม่ Excel
        </v-btn>
        <v-btn :elevation="1" color="primary" class="pa-2" @click="UpdateStock(null)">
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          เพิ่มรายการใหม่
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> ร้านค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-select
              v-model="search.shop"
              :items="search.shopList"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
              dense hide-details
              outlined
              single-line
            ></v-select>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> บาร์โค้ด : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.barcode"
              @change="value => search.barcode = Trim_value(value)"
              @keyup.enter="SearchStock()"
              outlined
              dense hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> เช็ตสินค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-select
              v-model="search.isProductSetTemp"
              :items="search.isProductSetList"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
              dense hide-details
              outlined
              single-line
            ></v-select>
          </v-col>
          <v-col class="pb-1" cols="12" md="2" lg="1"> SKU : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.sku"
              @change="value => search.sku = Trim_value(value)"
              @keyup.enter="SearchStock()"
              outlined
              dense hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col class="pb-1" cols="12" md="2" lg="1"> รหัสสินค้า : </v-col>
          <v-col class="pb-1" cols="12" md="4">
            <v-text-field
              :value="search.productCode"
              @change="value => search.productCode = Trim_value(value)"
              @keyup.enter="SearchStock()"
              outlined
              dense hide-details
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" lg="1"/>
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn :elevation="1" color="primary" class="pa-2 mt-1" dark @click="SearchStock()">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
                <vue-excel-xlsx
                    id="GetExcel"
                    v-show="false"
                    :data="datasetExcel"
                    :columns="headerExcel"
                    :file-name="'myproduct'"
                    :sheet-name="'shee1'"
                    >
                </vue-excel-xlsx>
                <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="ExportExcel()">
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                  <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                ร้านค้า :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="search.shop"
                  :items="search.shopList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                เช็ตสินค้า :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="search.isProductSetTemp"
                  :items="search.isProductSetList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                รหัสสินค้า :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.productCode"
                  @change="value => search.productCode = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                บาร์โค้ด :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.barcode"
                  @change="value => search.barcode = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                SKU :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.sku"
                  @change="value => search.sku = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn :elevation="1" color="primary" class="pa-2 mt-1" dark @click="SearchStock()">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
                <vue-excel-xlsx
                    id="GetExcel"
                    v-show="false"
                    :data="datasetExcel"
                    :columns="headerExcel"
                    :filename="'myproduct'"
                    :sheetname="'shee1'"
                    >
                </vue-excel-xlsx>
                <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="ExportExcel()">
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                  <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->

      </v-card-text>
    </v-card>

    <!-- แสดงแถวข้อมูล -->
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 pl-1">
        ทั้งหมด {{ formatMoney(total) }} แถว
      </v-col>
    </v-row>

    <!-- แสดงตาราง -->
    <v-card>
      <v-tabs v-model="tab" dark background-color="teal darken-3" show-arrows>
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
        <v-tab v-for="i in tab_list" :key="i.id" @click="ChangTab(i.id)"> {{ i.name+" ("+formatMoney(i.total)+")" }} </v-tab>
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item v-for="i in tab_list" :key="i.id">

        <!-- PC hidden-xs-only -->
        <v-data-table
          :headers="header"
          :items="dataset"
          :loading="tableloading"
          :items-per-page="9999999999"
          hide-default-footer
          class="packhai-border-table hidden-xs-only"
        >
          <template v-for="(header, index) in header" v-slot:[`header.${header.value}`]="{ header }">
            <v-tooltip :key="index" v-slot:activator="{ on }">
              <span>{{ header.text }}</span>
              <span v-if="header.value=='name' || header.value=='prop1' || header.value=='unitPrice' || header.value=='productCode' || header.value=='cfCode'|| header.value=='sku' || header.value=='barcode'">
                <v-icon v-if="header.icon==0" @click="OrderByCollum(index)" size="16" class="icon-white">
                  mdi-arrow-up
                </v-icon>
                <v-icon v-else-if="header.icon==1" @click="OrderByCollum(index)" size="16" >
                  mdi-arrow-up
                </v-icon>
                <v-icon v-else-if="header.icon==2" @click="OrderByCollum(index)" size="16" >
                  mdi-arrow-down
                </v-icon>
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.no="{ item }">
            {{ formatMoney((i.total-dataset.indexOf(item))-offsets) }}
          </template>
          <template v-slot:item.photoLink="{ item }">
            <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
              <img :src="item.photoLink" class="picsize mt-1">
            </a>
          </template>
          <template v-slot:item.name="{ item }">
            <span class="fn-13">
              {{item.name}}
            </span>
          </template>
          <template v-slot:item.productCode="{ item }">
            <span class="fn-13">
              {{item.productCode}}
            </span>
          </template>
          <template v-slot:item.prop1="{ item }">
            <span class="fn-13">
              {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
            </span>
          </template>
          <template v-slot:item.barcode="{ item }">
            <span class="fn-13">
              {{item.barcode}}
            </span>
          </template>
          <template v-slot:item.sku="{ item }">
            <span class="fn-13">
              {{item.sku}}
            </span>
          </template>
          <template v-slot:item.unitPrice="{ item }">
            <span class="fn-13">
              {{ formatMoney(item.unitPrice) }}
            </span>
          </template>
          <template v-slot:item.isProductSet="{ item }">
            <span v-if="item.isProductSet" class="fn-13">
              <v-icon size="22" color="orange darken-3">mdi mdi-pin</v-icon>
            </span>
          </template>
          <template v-slot:item.edit="{ item }">
            <a @click="UpdateStock(item)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
          </template>
          <template v-slot:item.delete="{ item }">
            <a @click="DeleteStock(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
          </template>
        </v-data-table>

        <!-- Mobile hidden-sm-and-up -->
        <v-data-iterator
          :items="dataset"
          :items-per-page="9999999999"
          hide-default-header
          hide-default-footer
          class="hidden-sm-and-up"
        >
          <template v-slot:default="props">
            <v-row class="ma-0">
              <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                <v-card>
                  <v-card-title class="subheading font-weight-bold pa-0">
                    <div class="px-0 v-list-item">
                      <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                        <img :src="item.photoLink" class="picsize">
                      </a>
                      <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                        <span class="bold-color-gray-12"> No Picture </span>
                      </a>
                      <div class="v-list-item__content pa-2 pl-0" >

                        <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                          <div>
                            <div>
                              ชื่อ
                            </div>
                            <div style="line-height: 1rem !important;">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on" class="cut-text">
                                    <span v-if=" item.name != null">: {{ item.name }}</span>
                                  </div>
                                </template>
                                <span v-if=" item.name != null">{{ item.name }}</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="v-markdown subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                ลักษณะ
                              </div>
                              <div class="cut-text">
                                : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                              </div>
                            </div>

                            <br/>
                            <div>
                              <div>
                                รหัสสินค้า
                              </div>
                              <div class="cut-text">
                                : {{ item.productCode }}
                              </div>
                            </div>

                            <br/>
                            <div>
                              <div>
                                SKU
                              </div>
                              <div class="cut-text">
                                : {{ item.sku }}
                              </div>
                            </div>

                            <br/>
                            <div>
                              <div>
                                Barcode
                              </div>
                              <div class="cut-text">
                                : {{ item.barcode }}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </v-card-title>
                  <v-divider/>

                  <div class="px-0 v-list-item">
                    <div class="v-list-item__content pa-2 pt-0">
                      <div>
                        <div class="v-markdown subtitle-1 packhai-showproduct-2">
                          <div>
                            <div>
                              ร้านค้า
                            </div>
                            <div class="cut-text">
                              : {{ item.shopName }}
                            </div>
                          </div>

                          <br/>
                          <div>
                            <div>
                              ราคาขาย
                            </div>
                            <div class="cut-text">
                              : {{ formatMoney(item.unitPrice) }}
                            </div>
                          </div>

                          <br/>
                          <div>
                            <div>
                              รหัส CF
                            </div>
                            <div class="cut-text">
                              : {{ item.cfCode }}
                            </div>
                          </div>

                          <br/>
                          <div>
                            <div>
                              หมายเหตุ
                            </div>
                            <div class="cut-text">
                              : {{ item.remark }}
                            </div>
                          </div>

                          <br/>
                          <div>
                            <div>
                              เช็ตสินค้า
                            </div>
                            <div>
                              :
                              <span v-if="item.isProductSet" class="fn-13">
                                <v-icon size="22" color="orange darken-3">mdi mdi-pin</v-icon>
                              </span>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    <div class="pa-2 pt-1">
                      <div>
                        <div class="v-markdown subtitle-1 packhai-showproduct-2">
                          <div>
                            <div class="pr-1">
                            </div>
                          </div>

                          <br/>
                          <div>
                            <div class="pr-1">
                            </div>
                          </div>

                          <br/>
                          <div>
                            <div class="pr-1">
                            </div>
                          </div>

                          <br/>
                          <div>
                            <div class="pr-1">
                              <v-spacer/>
                              <a @click="UpdateStock(item)"><v-icon size="15" color="orange darken-3">fa-edit</v-icon></a>
                            </div>
                          </div>

                          <br/>
                          <div>
                            <div class="pr-1">
                              <v-spacer/>
                              <a @click="DeleteMarketplace(item)"><v-icon size="15" color="red darken-3">fa-trash-alt</v-icon></a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

      </v-tab-item>
    </v-tabs-items>

    <!-- เปลี่ยนหน้า -->
    <v-row v-if="pageLength>1 && dataset.length>0" class="text-center pa-2 ma-0" @click="changePage()">
      <v-col cols="12" class="pa-0">
        <v-pagination
          v-model="page"
          :length="pageLength"
          :total-visible="pageTotalVisible"
          color="info"
        ></v-pagination>
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import Loading from '@/website/components/Loading'
  import { stockService_dotnet, shopService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { formatMoney, format_price, isNumberWithDot, isNumberWNoDot, DateNowNotNull, Trim_value, Trim_rules } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      // page
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,

      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',

      // ค้นหา
      shopList: [],
      search: {
        shopList: [{id: 0, name: "--ทั้งหมด--"}],
        shop: {id: 0, name: "--ทั้งหมด--"},
        "name": null,
        "productCode": null,
        "barcode": null,
        "sku": null,
        "isProductSet": 2,
        "isProductSetTemp": {"id":2, "name":"--ทั้งหมด--"},
        "isProductSetList": [
          {"id":2, "name":"--ทั้งหมด--"},
          {"id":1, "name":"ใช่"},
          {"id":0, "name":"ไม่ใช่"}
        ],
        orderbyName: 'id',
        isAscending: false,
      },

      // Export Excel
      loadingExcel: 0,
      datasetExcel: [],
      headerExcel: [
        { label: "No", field: "no", width: 10},
        { label: "ชื่อ", field: "name", width: '50px'},
        { label: "ลักษณะ", field: "prop1", width: 20},
        { label: "ราคาขาย", field: "unitPrice", width: 15},
        { label: "รหัสสินค้า", field: "productCode", width: 15},
        { label: "รหัส CF", field: "cfCode", width: 15},
        { label: "SKU", field: "sku", width: 15},
        { label: "Barcode", field: "barcode", width: 15},
        { label: "ร้านค้า", field: "shopName", width: '50px'},
        { label: "หมายเหตุ", field: "remark", width: 20},
        { label: "เช็ตสินค้า", field: "isProductSet", width: 10},
      ],

      // tab
      tab: 0,
      tab_list: [
        {"id":0, "name":"--ทั้งหมด--", "total": 0},
        {"id":1, "name":"สินค้าของฉัน", "total": 0},
        {"id":2, "name":"เช็ตสินค้า", "total": 0},
      ],

      // แสดงตาราง
      tableloading: false,
      total: 0,
      dataset: [],
      header: [
        { text: 'No',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'ราคาขาย',align: 'center',sortable: false,value: 'unitPrice', icon: 0},
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', icon: 0},
        { text: 'รหัส CF',align: 'center',sortable: false,value: 'cfCode', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode', icon: 0},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
        { text: 'หมายเหตุ',align: 'center',sortable: false,value: 'remark', icon: 0},
        { text: 'เช็ตสินค้า',align: 'center',sortable: false,value: 'isProductSet', icon: 0},
        { text: 'แก้ไข',align: 'center',sortable: false,value: 'edit', icon: 0, width: '60px'},
        { text: 'ลบ',align: 'center',sortable: false,value: 'delete', icon: 0, width: '60px' },
      ],

      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 50,
      offsets: 0,

    }),
    watch: {
    },
    async created() {
      this.GetShop()
      this.Load_Data(this.limits,this.offsets,this.search,1,0)
      this.Load_Count(this.limits,this.offsets,this.search,0,true)
      this.page_loading = false
    },
    methods: {
      async GetShop() {
        let response = await axios.post(shopService_dotnet+'Shop/get-shop-list', {
          "shopType": 1,
          "branchId": localStorage.getItem('Branch_BranchID'),
        }, {headers: this.header_token})
        if (response.status ==200) {
          this.shopList = response.data.result
          if (this.shopList.length>0){
            for (var j in this.shopList){
              this.search.shopList.push({
                id: this.shopList[j].shop.id,
                name: this.shopList[j].shop.name,
              })
            }
          }
        }
      },
      async ChangTab(tab){
        this.reSetSort()
        this.Load_Data(this.limits,0,this.search,1,tab)
        this.Load_Count(this.limits,0,this.search,tab,false)
        this.reSetIconHeader()
      },
      reSetSort(){
        this.search.orderbyName = 'id'
        this.search.isAscending = false
      },
      reSetIconHeader(){
        for (var i in this.header){
          this.header[i].icon = 0
        }
      },
      async SearchStock(){
        this.reSetSort()
        this.Load_Data(this.limits,0,this.search,1,0)
        this.Load_Count(this.limits,0,this.search,0,true)
        this.reSetIconHeader()
      },
      async Load_Data(limits,offsets,search,page,tab) {
        search.isProductSet = search.isProductSetTemp.id
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku
        searchTemp["isProductSet"] = search.isProductSet==2 ? ((tab==0) ? null : ((tab==1) ? 0 : 1)) : search.isProductSet
        searchTemp["orderbyName"] = search.orderbyName
        searchTemp["isAscending"] = search.isAscending
        if ((search.isProductSet==1 && tab==1) || (search.isProductSet==0 && tab==2)){
          this.dataset = []
          this.tab = tab
          this.page = page
          this.pageTemp = page
          this.offsets = offsets
          return
        }
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-stock-productMasterItem-list', {
          "branchId": localStorage.getItem('Branch_BranchID'),
          "shopId": search.shop.id==0 ? null : search.shop.id,
          "keyword": searchTemp,
          "skip": offsets,
          "take": limits,
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status ==200) {
          this.loading = false
          this.tab = tab
          this.page = page
          this.pageTemp = page
          this.offsets = offsets
          this.dataset = []
          this.dataset = response.data.resultData
        } else {
          this.loading = false
          this.AlertError()
        }
      },
      async Load_Count(limits,offsets,search,tab,isNeedWarehouseCout) {
        search.isProductSet = search.isProductSetTemp.id
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku
        searchTemp["isProductSet"] = search.isProductSet==2 ? ((tab==0) ? null : ((tab==1) ? 0 : 1)) : search.isProductSet
        let response = await axios.post(stockService_dotnet+'Stock/get-stock-productMasterItem-count', {
          "branchId": localStorage.getItem('Branch_BranchID'),
          "shopId": search.shop.id==0 ? null : search.shop.id,
          "keyword": searchTemp,
          "skip": offsets,
          "take": limits,
          "isNeedWarehouseCout": isNeedWarehouseCout
        }, {headers: this.header_token})
        if (response.status ==200) {
          if ((search.isProductSet==1 && tab==1) || (search.isProductSet==0 && tab==2)){
            response.data.resultCount = 0
            this.tab_list[tab].total = 0
          }
          this.total = response.data.resultCount
          this.tab_list[tab].total = response.data.resultCount
          if (isNeedWarehouseCout){
            for (var i in response.data.productCount){
              if (response.data.productCount[i].id==0){
                this.tab_list[1].total = response.data.productCount[i].totalProductCount
              }
              else if (response.data.productCount[i].id==1){
                this.tab_list[2].total = response.data.productCount[i].totalProductCount
              }
            }
            this.tab_list[0].total = response.data.resultCount
          }
          this.pageLength =  (Math.ceil(response.data.resultCount/this.limits))
          if (this.pageLength<=0){this.pageLength=1}
        } else {
          this.AlertError()
        }
      },

      async ExportExcel(){
        this.datasetExcel = []
        this.loadingExcel = 0
        Swal.fire({
          position: "top",
          icon: 'warning',
          text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async(result) => {
          if (result.value) {

            var limits = 50
            var offsets = 0
            this.loadingExcel = 1
            var total = this.tab_list[0].total
            var round =parseInt( total/limits)

            if ( limits * round < total ){
              round = round+1
            }

            for (var i = 1; i <= round; i++) {

              var data = await this.GetExportExcelProduct(limits,offsets)
              for (var j in data){
                data[j].no = (data.length - parseInt(j))
                this.datasetExcel.push(data[j])
              }
              this.loadingExcel = parseInt( parseFloat(i) / parseFloat(round) * parseFloat(100) )
              offsets = offsets+ limits
            }

            for (var d in this.datasetExcel){
              this.datasetExcel[d]["no"] = this.datasetExcel.length - parseInt(d)
            }

            this.loadingExcel = this.datasetExcel.length==0 ? 0 : 100
            document.getElementById("GetExcel").click();
          }
        })
      },
      async GetExportExcelProduct(limits,offsets){
        try {
          var search = this.search
          search.isProductSet = search.isProductSetTemp.id
          var searchTemp = {}
          searchTemp["name"] = search.name
          searchTemp["productCode"] = search.productCode
          searchTemp["barcode"] = search.barcode
          searchTemp["sku"] = search.sku
          searchTemp["isProductSet"] = search.isProductSet==2 ? null : search.isProductSet
          searchTemp["orderbyName"] = search.orderbyName
          searchTemp["isAscending"] = search.isAscending
          let stockList = await axios.post(stockService_dotnet+'Stock/get-stock-productMasterItem-list', {
            "branchId": localStorage.getItem('Branch_BranchID'),
            "shopId": this.search.shop.id==0 ? null : this.search.shop.id,
            "keyword": searchTemp,
            "skip": offsets,
            "take": limits,
          }, {headers: this.header_token})
          if (stockList.status == 200){
            return stockList.data.resultData
          }else{
            return []
          }
        }

        catch (e) {
          console.log('error');
        }
      },
      UpdateStock(item){
        var data = {
          "productMasterId": null,
          "productMasterItemId": null,
          "isProductSet": 0,
          "part_return": "/my-product",
        }
        if (item!=null){
          data.productMasterId = item.productMasterId
          data.productMasterItemId = item.id
          data.isProductSet = item.isProductSet ? 1 : 0
          localStorage.setItem('my-product-search', JSON.stringify(this.search))
          localStorage.setItem('my-product-limits', this.limits)
          localStorage.setItem('my-product-offsets', this.offsets)
          localStorage.setItem('my-product-page', this.page)
          localStorage.setItem('my-product-tab', this.tab)
        } else {
          localStorage.removeItem("my-product-search");
          localStorage.removeItem("my-product-limits");
          localStorage.removeItem("my-product-offsets");
          localStorage.removeItem("my-product-page");
          localStorage.removeItem("my-product-tab");
        }
        localStorage.setItem('data', JSON.stringify(data))
        window.open("/my-product/update");
        // this.$router.push("/my-product/update")
      },
      async changePage() {
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          var offsets = (this.page*this.limits)-this.limits
          this.Load_Data(this.limits,offsets,this.search,this.page,this.tab)
          this.pageTemp = this.page
        }
      },
      async OrderByCollum(index){
        this.tableloading = true
        this.search.orderbyName = this.header[index].value
        this.search.isAscending = this.header[index].icon==0 ? false : (this.header[index].icon==1 ? true : false)
        for (var i in this.header) {
          if (!(parseInt(index) == parseInt(i))){
            this.header[i].icon = 0
          }
        }
        this.header[index].icon = this.header[index].icon==0 ? 1 : (this.header[index].icon==1 ? 2 : 1)
        await this.Load_Data(this.limits,this.offsets,this.search,this.page,this.tab)
        this.tableloading = false
      },
      async DeleteStock(item){
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ลบ ใช่หรือไม่',
          text: item.name +' '+ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : ""),
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true
            let response = null
            if (item.isProductSet){
              response = await axios.post(stockService_dotnet+'Stock/delete-productset', {
                "productMasterId": item.productMasterId,
              }, {headers: this.header_token})
            }else{
              response = await axios.post(stockService_dotnet+'Stock/delete-stock', {
                "productMasterItemId": item.id,
              }, {headers: this.header_token})
            }
            if (response.status==200 || response.status==204) {
              if (item.isProductSet){
                if (response.data.status==200){
                  await this.SearchStock()
                  this.loading = false
                }else{
                  this.loading = false
                  this.AlertWarning(response.data.text)
                }

              }else{
                if (response.data.status==200){
                  await this.SearchStock()
                  this.loading = false
                  this.AlertSuccess()
                }else{
                  this.loading = false
                  this.AlertWarning(response.data.text)
                }
              }
            }else{
              this.loading = false
              this.AlertError()
            }
          }
        })
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      formatMoney,
      format_price,
      isNumberWithDot,
      isNumberWNoDot,
      DateNowNotNull,
      Trim_value,
      Trim_rules,
      AlertSuccess,
      AlertWarning,
      AlertError,
    }
  }
</script>

<style scoped>
  >>>.v-select__slot{
    height: 40px;
  }
</style>
